<template>
  <div></div>
</template>

<script>
export default {
  name: "check-adoption",
  mounted() {
    console.log('check')
  }
}
</script>

<style lang="scss" scoped>

</style>
